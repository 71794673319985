<template>
  <v-card>
    <v-container>
      <h3>หน้าแสดงข้อมูลดูรายการขอแบบประเมิน</h3>
      <v-row>
        <v-col cols="4" class="mr-4">
          <v-text-field
            v-model="search"
            dense
            hide-details
            outlined
            placeholder="ค้นหา"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :search="search"
        :items-per-page="10"
        class="elevation-1"
        :loading="loading"
        loading-text="กำลังโหลดข้อมูล...กรุณารอสักครู่"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-row justify="center">
            <v-btn v-if="item.status == `ยืนยันแล้ว`" disabled>ส่งแล้ว</v-btn>
            <v-btn @click="approve(item)" v-else>ยังไม่ส่ง</v-btn>
          </v-row>
        </template>
        <template v-slot:[`item.count`]="{ item }">
          {{ item.count }}
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>
<script>
import axios from "axios";
import { Decode } from "@/services";
export default {
  data() {
    return {
      search: "",
      loading: true,
      headers: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "ชื่อ-นามสุกล", value: "user.name", align: "center" },
        { text: "รหัสบัตรประชาชน", value: "user.idCard", align: "center" },
        { text: "เมลล์", value: "user.email", align: "center" },
        { text: "สถานะ", value: "status", align: "center" },
        { text: "การจัดการ", value: "action", align: "center" },
      ],
      list: [],
      count: 0,
      checkuser: [],
    };
  },
  created() {
    this.checkuser = JSON.parse(Decode.decode(localStorage.getItem("user")));
    this.getrequestDocument();
  },

  methods: {
    async approve(item) {
      if (item.status !== `ยืนยันแล้ว`) {
        console.log("logiten", item);
        this.$swal({
          text: "คุณต้องการเปลียนแปลงสถานะ ใช่ หรือ ไม่ ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ใช่",
          cancelButtonText: "ยกเลิก",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const auth = {
              headers: { Authorization: `Bearer` + " " + this.checkuser.token },
            };

            const response = await axios.put(
              `${process.env.VUE_APP_API}/requestDocument/approve/` + item.id,
              auth
            );
            console.log("ดาต้าapprove", response.data);
            this.$swal.fire({
              icon: "success",
              text: "คุณต้องการเปลียนแปลงสถานะ สำเร็จ !",
              showConfirmButton: false,
              timer: 1000,
            });
            await this.getrequestDocument();
          }
        });
      }
    },
    async getrequestDocument() {
      // console.log(token);
      const auth = {
        headers: { Authorization: `Bearer` + " " + this.checkuser.token },
      };

      const response = await axios.get(
        `${process.env.VUE_APP_API}/requestDocument`,
        auth
      );
      console.log("ดาต้าร้องขอ", response.data.data);
      this.list = response.data.data;
      for (let i in this.list) {
        this.list[i].count = parseInt(i) + 1;
        // console.log(this.listdata);
      }
      this.loading = false
    },
  },
};
</script>
<style scoped>
.f-right {
  float: right;
}
</style>
